import React from "react"
import Layout from "../components/Layout"
import LargeCard from "./LargeCard"

export default function HtmlPage({data, b2c}) {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout page="home" b2c={b2c} title={frontmatter.title} subtitle={frontmatter.subtitle}>
      <div className="html-page" dangerouslySetInnerHTML={{__html: html}} />
      {frontmatter.testimonial_card && <LargeCard cards={frontmatter.testimonial_card}/>}
    </Layout>
  )
}

