import React from "react";

const LargeCard = ({cards, cta}) => (
  <section className="testimonials section">
    <div className="container">
      <div className="testimonials-inner">
        <div className="testimonials-wrap">
          {cards.map(card => {
            return           <div className="testimonial text-center text-xs ">
              <div className="testimonial-inner">
                <div className="testimonial-main">
                  <div className="testimonial-header">
                    <img
                      className="mb-16"
                      src={card.thumbnail || card.image}
                      alt="Testimonial"
                    />
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-name">{card.title}</div>
                      <div className="testimonial-link">
                        <i>{card.subtitle}</i>
                      </div>
                      <div className="testimonial-link">
                        <a href={`mailto:${card.email}`}>
                          {card.email}
                        </a>
                        <br />
                        <a href={`mailto:${card.phone_number}`}>
                          {card.phone_number}
                        </a>
                      </div>
                    </div>
                    <div className="testimonial-body">
                      <div dangerouslySetInnerHTML={{__html: card.content}} style={{fontSize: "22px", color: "#000000"}}/>
                  </div>
                </div>
              </div>
              <div style={{top: -60, position: "relative"}} className="cta">{cta && cta}</div>

            </div>

          })}
      </div>
    </div>
    </div>
  </section>
);

export default LargeCard;
